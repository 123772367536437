const cityList = [
  '基隆市',
  '台北市',
  '臺北市',
  '新北市',
  '桃園縣',
  '新竹市',
  '新竹縣',
  '苗栗縣',
  '台中市',
  '臺中市',
  '彰化縣',
  '南投縣',
  '雲林縣',
  '嘉義市',
  '嘉義縣',
  '台南市',
  '臺南市',
  '高雄市',
  '屏東縣',
  '台東縣',
  '臺東縣',
  '花蓮縣',
  '宜蘭縣',
  '澎湖縣',
  '金門縣',
  '連江縣',
];

export { cityList };
