import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import imageStopwatch from 'images/icons/i-stopwatch.svg';
import DateSelector from '../date-selector';

const DeliveryBarWrapper = styled.div`
  height: 54px;
  padding: 0 15px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .image {
    margin-left: 12px;
  }

  .title {
    margin-left: 5px;
    font-size: 16px;
    color: #000000;
  }
`;

const OpenButton = styled.button`
  font-size: 14px !important;
  color: #214a4c !important;
  width: 90px;
  height: 30px;
  background: rgb(95, 210, 218);
  border: 1px solid rgb(33, 74, 77);
  box-sizing: border-box;
  border-radius: 30px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 0;
`;

const DeliveryBar = ({
  timeslots,
  setSelectTimeSlots,
  defaultSelectIndex,
  shippingModel = 1,
  getTimeSlots = () => {},
}) => {
  const [selectDeliveryTime, setSelectDeliveryTime] = useState();
  const [updatePicker, setUpdatePicker] = useState(false);
  const [showDateSelector, setShowDateSelector] = useState(false);

  const handleClickOpenBtn = () => {
    setShowDateSelector(true);
    getTimeSlots();
  };

  useEffect(() => {
    if (selectDeliveryTime !== undefined && selectDeliveryTime != null) {
      setSelectTimeSlots(timeslots[selectDeliveryTime]);
    }
  }, [selectDeliveryTime]);

  useEffect(() => {
    setSelectDeliveryTime(defaultSelectIndex);
    setUpdatePicker(!updatePicker);
  }, [timeslots]);

  useEffect(() => {
    setSelectDeliveryTime(defaultSelectIndex);
    setUpdatePicker(!updatePicker);
  }, [defaultSelectIndex]);

  return (
    <>
      <DeliveryBarWrapper>
        <TitleBlock>
          <img className="image" src={imageStopwatch} />
          <span className="title" key={timeslots[selectDeliveryTime]}>
            {timeslots.length > 0 && selectDeliveryTime !== undefined
              ? timeslots[selectDeliveryTime].shippingPeriod === '-1'
                ? shippingModel === '1'
                  ? '即刻配送'
                  : `到店取貨 ${timeslots[selectDeliveryTime].shippingDate} ${timeslots[selectDeliveryTime].shippingTimeslot}`
                : `${shippingModel === '1' ? '' : '到店取貨 '}${
                    timeslots[selectDeliveryTime].shippingDate
                  } ${timeslots[selectDeliveryTime].shippingTimeslot}`
              : shippingModel === '1'
              ? '即刻配送'
              : timeslots !== undefined &&
                selectDeliveryTime === 0 &&
                timeslots[selectDeliveryTime] !== undefined
              ? `${shippingModel === '1' ? '' : '到店取貨 '}${
                  timeslots[selectDeliveryTime].shippingDate
                } ${timeslots[selectDeliveryTime].shippingTimeslot}`
              : '到店取貨'}
          </span>
        </TitleBlock>
        <OpenButton onClick={handleClickOpenBtn}>安排時間</OpenButton>
        <DateSelector
          key={updatePicker}
          title={shippingModel === '1' ? '安排外送時間' : '安排取貨時間'}
          options={timeslots}
          isShow={showDateSelector}
          shippingModel={shippingModel}
          selectValue={selectDeliveryTime}
          onUpdate={(selectedId) => {
            setShowDateSelector(false);
            if (selectedId !== -1) {
              setSelectDeliveryTime(selectedId);
            }
          }}
        />
      </DeliveryBarWrapper>
    </>
  );
};

export default DeliveryBar;
