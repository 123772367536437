import localStorage from 'localStorage';

// const mCache = new cache({ stdTTL: process.env.DATA_CACHE_TIME / 1000, checkperiod: 120 });

const setLocalStorage = (key, value, expire = null) => {
  // if (expire !== null) {
  //     return mCache.set(key, value, expire);
  // } else {
  //     return mCache.set(key, value);
  // }

  localStorage.setItem(
    key,
    JSON.stringify({
      ttl:
        expire === null
          ? Date.now() + Number.parseInt(process.env.DATA_CACHE_TIME)
          : Date.now() + Number.parseInt(expire),
      data: value,
    })
  );
};

const getLocalStorage = (key) => {
  // let value = mCache.get(key);
  // if (value === undefined) {
  //     return null;
  // } else {
  //     return value;
  // }

  let value = localStorage.getItem(key);

  if (value === null) {
    return null;
  }

  value = JSON.parse(value);

  if (value.ttl < Date.now()) {
    localStorage.removeItem(key);
    return null;
  } else {
    return value.data;
  }
};

const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export { setLocalStorage, getLocalStorage };
