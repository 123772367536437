import React, { useState, useEffect } from 'react';
import zhTW from 'date-fns/locale/zh-TW';
import { formatWithOptions } from 'date-fns/fp';
import { formatRelative } from 'date-fns';

const DateSelector = ({
  title = '標題',
  options = [],
  isShow = false,
  shippingModel = '1',
  onUpdate = () => {},
  selectValue,
}) => {
  const [selectedId, setSelectedId] = useState(selectValue);

  const dateToString = formatWithOptions({ locale: zhTW }, 'M月d日 EE');
  const getRelative = (date) => formatRelative(date, new Date(), { locale });

  const formatRelativeLocale = {
    lastWeek: "'上週' eeee",
    yesterday: '昨天',
    today: '今天',
    tomorrow: '明天',
    nextWeek: "'下週' eeee'",
    other: 'yyyy/MM/dd',
  };

  const locale = {
    ...zhTW,
    formatRelative: (token) => formatRelativeLocale[token],
  };

  useEffect(() => {
    if (selectedId === undefined) {
      setSelectedId(0);
    }
  }, [selectedId]);

  return (
    <>
      <div className={`date-selector ${isShow ? 'show' : ''}`}>
        <h2 className="title">{title}</h2>
        <ul className="date-list">
          {options.map((option, index) => (
            <li
              key={index}
              className={`date-item ${index === selectedId ? 'selected' : ''}`}
              checked={index === selectedId}
              onClick={() => {
                setSelectedId(index === selectedId ? -1 : index);
              }}
            >
              {option.shippingPeriod !== '-1' ? (
                <>
                  <div className="date-box">
                    <div className="date-note">
                      {getRelative(new Date(option.shippingDate))}
                    </div>
                    <div className="date">
                      {dateToString(new Date(option.shippingDate))}
                    </div>
                  </div>
                  <div className="time">{option.shippingTimeslot}</div>
                </>
              ) : (
                <>
                  {shippingModel === '1' ? (
                    <p>即刻配送</p>
                  ) : (
                    <>
                      <div className="date-box">
                        <div className="date-note">
                          {getRelative(new Date(option.shippingDate))}
                        </div>
                        <div className="date">
                          {dateToString(new Date(option.shippingDate))}
                        </div>
                      </div>
                      <div className="time">{option.shippingTimeslot}</div>
                    </>
                  )}
                </>
              )}
            </li>
          ))}
        </ul>
        <button className="button-update" onClick={() => onUpdate(selectedId)}>
          更新
        </button>
      </div>
      <style jsx>
        {`
          .date-selector {
            height: 336px;
            box-sizing: border-box;
            font-family: 'Noto Sans TC';
            font-style: normal;
            position: fixed;
            bottom: -100vh;
            left: 0;
            right: 0;
            padding: 17px 11px 53px 12px;
            background-color: #fff;
            box-shadow: 0px -1px 24px #00000020;
            z-index: 200;
            transform: translate3d(0, 0, 200);
            -webkit-transform: translate3d(0, 0, 200);
            transition: 500ms;

            &.show {
              bottom: 0;
            }

            .title {
              font-weight: 700;
              font-size: 18px;
              line-height: 26px;
              text-align: center;
              color: #333333;
              margin-bottom: 21px;
            }

            .date-list {
              margin-bottom: 16px;
              height: 190px;
              overflow: auto;

              .date-item {
                padding: 9px 13px 10px 10px;
                margin-bottom: 2px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 8px;

                &.selected {
                  background: #f7f7f7;
                }

                .date-box {
                  .date-note {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 17px;
                    color: #999999;
                  }
                  .date {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #000;
                  }
                }

                .time {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  color: #000;
                }
              }
            }

            .button-update {
              font-weight: 400;
              font-size: 20px;
              line-height: 29px;
              text-align: center;
              color: #fff;
              height: 60px;
            }
          }
        `}
      </style>
    </>
  );
};

export default DateSelector;
