import React, { useState, useEffect } from 'react';
import BottomSheet from '../../components/bottom-sheet';
import imageLocationPoint from '../../images/icons/i-location-point.svg';
import Check from '../../images/icons/round-check-fill.svg';
import GoogleMapFrame from '../../components/google-map';
import { useInput, useSelectableList } from 'rooks';
import { useAPI } from '../../utils/api';
const AddressBlock = ({ address, updateAddress }) => {
  const [input, setInput] = useState(address);
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      updateAddress(event.target.value);
    }
  };
  const search = (event) => {
    setInput(event.target.value);
    updateAddress(event.target.value);
  };
  return (
    <>
      <div className="address-bar">
        <div className="address-block">
          <img src={imageLocationPoint} />
          <div className="address">
            <input
              className="addressInput"
              placeholder="請輸入地址"
              value={input}
              onBlur={search}
              onChange={(event) => setInput(event.target.value)}
              onKeyDown={handleKeyDown}
            ></input>
          </div>
          <div className="store" hidden>
            北投店
          </div>
        </div>
      </div>
      <style jsx>{`
        .address-bar {
          width: 100%;
          box-sizing: border-box;
        }
        .address-block {
          height: 47px;
          background: #ffffff;
          border: 1px solid #5fd2da;
          box-sizing: border-box;
          padding: 0 12.5px 0 5.15px;
          border-radius: 3px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .address {
            flex-grow: 1;
            padding: 0 5px;
            color: #333333;
            .addressInput {
              width: 90%;
              background: transparent;
              border: 0;
              outline: none;
            }
          }
          .store {
            font-weight: bold;

            color: #333333;
          }
        }
      `}</style>
    </>
  );
};
const NewAddress = ({ isShow, data, setIsShow, clickUpdate }) => {
  const api = useAPI();
  // location
  const [location, setLocation] = useState({ lat: 25.04, lng: 121.51 });
  const zoom = 16;

  const nameInput = useInput(data.fullName);
  const phoneInput = useInput(data.phone);
  // const floorInput = useInput();
  // const companyInput = useInput(data.companyName);
  const tagInput = useInput(data.tag);
  const remarkInput = useInput(data.deliveryNote);
  // const addressInput = useInput(data.address);
  const [address, setAddress] = useState(data.address);
  const [updateAddress, setUpdateAddress] = useState(false);
  const [elevatorSelect, elevatorHandler] = useSelectableList(
    [true, false],
    data.deliveryIsElevator ? 0 : 1
  );
  const [receiveMethodSelect, receiveMethodHandler] = useSelectableList(
    [1, 2, 3],
    data.deliveryPointValue !== -1 ? data.deliveryPointValue - 1 : -1
  );

  const [receiveTypeOptions, setReceiveTypeOptions] = useState([]);
  const [receiveTypeValue, setReceiveTypeValue] = useState('1');

  const [updateAddressData, setUpdateAddressData] = useState(data);

  const getOptions = () => {
    api
      .getReceiveType()
      .then((res) => {
        setReceiveTypeOptions(res);
        console.log('getReceiveType', res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log(isShow);
    if (isShow) {
      getOptions();
      getLocationFromServer(null, null, data.address);
      setReceiveTypeValue(data.deliveryPointValue);
    }
  }, []);

  const getLocationFromServer = (lat, lng, addr) => {
    api
      .getStoreByLocation(lat, lng, addr)
      .then((res) => {
        if (!res) {
          return;
        }
        setLocation({
          lat: res.query.latitude,
          lng: res.query.longitude,
        });
        setAddress(`${res.query.address}`);
        setUpdateAddress(!updateAddress);
      })
      .catch((error) => {
        console.log('get location by server exception =', error);
      });
  };

  useEffect(() => {
    setUpdateAddressData((prevState) => ({
      ...prevState,
      fullName: nameInput.value,
      phone: phoneInput.value,
      // floor: floorInput.value,
      // companyName: companyInput.value,
      tag: tagInput.value,
      deliveryNote: remarkInput.value,
      deliveryIsElevator: elevatorSelect[1],
      deliveryPointValue: receiveTypeValue, //receiveMethodSelect[1],
      address: address,
    }));
  }, [
    nameInput.value,
    phoneInput.value,
    // floorInput.value,
    // companyInput.value,
    tagInput.value,
    remarkInput.value,
    address,
    elevatorSelect[0],
    receiveTypeValue, //receiveMethodSelect
  ]);

  const handleAddressChange = (addr) => {
    setAddress(addr);
    getLocationFromServer(null, null, addr);
    setUpdateAddress(!updateAddress);
  };

  // useEffect(() => {
  //   getLocationFromServer(null, null, address);
  //   setUpdateAddress(!updateAddress);
  // }, [address]);

  const onSave = () => {
    clickUpdate(updateAddressData);
  };

  return (
    <>
      <BottomSheet title="地址資訊" isShow={isShow} setIsShow={setIsShow}>
        <div className="sheet">
          <div id="map">
            <GoogleMapFrame
              location={location}
              zoom={zoom}
              updateLocation={setLocation}
            />
          </div>
          <div className="form">
            <h2>收件人</h2>
            <input type="text" placeholder="請輸入姓名" {...nameInput} />
            <h2>電話</h2>
            <input
              type="text"
              placeholder="請輸入電話"
              maxLength={10}
              {...phoneInput}
            />
            <h2>地點</h2>
            <AddressBlock
              address={address}
              updateAddress={handleAddressChange}
              key={updateAddress}
            />
            {/* <div className="input-group">
              <input type="text" placeholder="樓層或房號" {...floorInput} />
              <input
                type="text"
                placeholder="公司或大樓名稱"
                {...companyInput}
              />
            </div> */}
            <h2>標籤</h2>
            <input type="text" placeholder="標籤" {...tagInput} />
            <div className="radios">
              <span className="radio-name">電梯</span>
              <input
                type="radio"
                name="elevator"
                id="y"
                checked={elevatorHandler.matchSelection({ index: 0 })}
                onChange={() => elevatorHandler.toggleSelection({ index: 0 })()}
              />
              <label htmlFor="y">有</label>
              <input
                type="radio"
                name="elevator"
                id="n"
                checked={elevatorHandler.matchSelection({ index: 1 })}
                onChange={() => elevatorHandler.toggleSelection({ index: 1 })()}
              />
              <label htmlFor="n">無</label>
            </div>
            <div className="radios">
              <span className="radio-name">簽收</span>
              {receiveTypeOptions &&
                receiveTypeOptions.map((item, index) => (
                  <div key={`div-receive-${index}`}>
                    <input
                      type="radio"
                      id={`receive-${index}`}
                      name="sign"
                      value={item.value}
                      checked={item.value === receiveTypeValue}
                      onChange={(e) => {
                        setReceiveTypeValue(e.target.value);
                      }}
                    />
                    <label htmlFor={`receive-${index}`}>{item.name}</label>
                  </div>
                ))}
              {/* <input
                type="radio"
                id="sign-y"
                name="sign"
                checked={receiveMethodHandler.matchSelection({ index: 0 })}
                onChange={receiveMethodHandler.toggleSelection({ index: 0 })}
              />
              <label htmlFor="sign-y">放在門口</label>
              <input
                type="radio"
                id="sign-n"
                name="sign"
                checked={receiveMethodHandler.matchSelection({ index: 1 })}
                onChange={receiveMethodHandler.toggleSelection({ index: 1 })}
              />
              <label htmlFor="sign-n">在門口收件</label>
              <input
                type="radio"
                id="sign-a"
                name="sign"
                checked={receiveMethodHandler.matchSelection({ index: 2 })}
                onChange={receiveMethodHandler.toggleSelection({ index: 2 })}
              />
              <label htmlFor="sign-a">在1樓門口收件</label> */}
              <p className="notice">
                如果您的樓層有安全管制，請通知管理室協助簽收。
              </p>
            </div>
            <h2 className="memo">地址備註</h2>
            <textarea
              placeholder="樓層/房號、公司大樓名稱、其他地址備註"
              {...remarkInput}
            ></textarea>
            <div className={`action-button ${isShow ? 'show' : ''}`}>
              <button onClick={onSave}>確認修改</button>
            </div>
          </div>
        </div>
      </BottomSheet>
      <style jsx>{`
        .action-button {
          position: relative;
          &.show {
            position: fixed;
          }
        }
        .sheet {
          height: calc(100vh - 91px);
          position: relative;
        }
        #map {
          width: 100%;
          height: 204px;
          background-color: #aaa;
          position: relative;
        }
        .form {
          position: relative;
          padding: 20px 18px 120px 22px;
        }
        h2 {
          padding-bottom: 8px;
        }
        input + h2 {
          padding-top: 11px;
        }
        .input-group {
          display: flex;
          gap: 8px;
          padding-top: 8px;
          padding-bottom: 20px;
        }
        input[type='text'] {
          width: 100%;
          background: #ffffff;
          border: 1px solid #5fd2da;
          box-sizing: border-box;
          border-radius: 3px;
          padding: 14px 12px 13px 12px;
          &::placeholder {
            opacity: 0.5;
          }
        }
        .radios {
          padding-top: 12px;
          .notice {
            padding-top: 9px;
            font-size: 14px;
            line-height: 20px;

            color: #000000;
          }
        }
        input[type='radio'] {
          display: none;
        }
        .radio-name {
          font-size: 18px;
          line-height: 26px;
          color: #333333;
          padding-right: 10px;
        }
        input[type='radio'] + label {
          position: relative;
          font-size: 14px;
          line-height: 20px;
          padding-left: 26px;
          color: #000000;
          & ~ label {
            margin-left: 14px;
          }
        }
        input[type='radio'] + label::before {
          position: absolute;
          left: 0;
          top: 0;
          content: '';
          display: inline-block;
          width: 16px;
          height: 16px;
          background-color: #ffffff;
          border: solid 1px #d2d2d2;
          border-radius: 50%;
          box-sizing: border-box;
        }
        input[type='radio']:checked + label::before {
          border: solid 6px #5fd2da;
        }
        .memo {
          padding-top: 11px;
        }
        textarea {
          box-sizing: border-box;
          width: 100%;
          min-height: 70px;
          resize: none;
          border: none;
          outline: none;
          font-size: 14px;
          line-height: 20px;
          background: #ffffff;
          border: 1px solid #5fd2da;
          box-sizing: border-box;
          border-radius: 3px;
          padding: 14px 12px 13px 12px;

          color: rgb(51, 51, 51);
          &::placeholder {
            color: rgba(51, 51, 51, 0.5);
          }
        }
      `}</style>
    </>
  );
};

export default NewAddress;
